.describtion_main {
    display:flex;
    flex-direction: row;
    width:100%;
    margin-left:auto;
    margin-right:auto;
    padding-top:5%;
    padding-bottom:5%;
    justify-content: center;
    background-color: #F2F1EF;
}

.describtion_main_image {
    background-repeat: no-repeat;
    background-size:cover;
    background-position: center center ;
    min-height: 80vh;
    margin-right:auto;
    width:100%;
    margin-left:10%;
    border-radius:4%;
}

.describtion_main_text {

    margin-top: auto;
    margin-bottom:auto;
    justify-content:center;
    margin-left:8%;
    padding-left:2%;
    border-left: solid 3px #ff6600;
    width: 60%;
}

@media only screen and (max-width: 780px) {
    .describtion_main {
        display:flex;
        flex-direction: column;
        width:100%; 
    }
    .describtion_main_text { 

        width:60%;
        margin-left:auto;
        margin-right:auto;
        justify-content: center;
        margin-top:10%;
        margin-bottom:10%;
    
    }

    .describtion_main_image {
        width:90%;
        padding:0%;
        margin-left:5%;
        margin-right:5%;
        justify-content: center;
        min-height: 40vh;
        
    }
}