
.news {
    display:block;
    width: 100%;
    margin-left:auto;
    margin-right:auto;
    margin-top: 0%;
    margin-bottom:0%;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 120vh;

}

.news_texts {
    width:100%;
    padding-top:10%;
}
.news__firsttextfield {

    padding: 0.8%;
    width: 62%;
    color: white;
    margin-left:auto;
    margin-right:auto;
    justify-content: center;
    margin-top:auto;
    margin-bottom:auto;
    background-color: rgba(255, 102, 0, 0.7);
    border-radius: 2%;

}
.news__secondtextfield { 
    margin-top:2%;
    padding: 0.8%;
    width: 60%;
    color: white;
    background-color: rgba(255, 102, 0, 0.7);
    border-radius: 2%;
    font-weight: lighter;
    margin-left:auto;
    margin-right:auto;
    margin-bottom:40%;
    justify-content: center;


}


@media only screen and (max-width: 780px) {
    .news {
        min-height: 50vh;
    }

    .news_texts {
        width:100%;
        padding-top:10%;
    }
    .news__secondtextfield {
      display: none;
    }
    .news__firsttextfield {
        height: 40%;
        margin-top:20%;
        margin-bottom:auto;
        justify-content: center;
        margin-left:auto;
        margin-right:auto;
        width: 90%;

    }
  }
/*  #ff6600 */