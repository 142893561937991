.sectors {
    width:100%;
    margin-bottom: 5%;

    border-top: 3px solid #ff6600;
}

.sectors_tag {
    margin-left: auto;
    margin-right: auto;
    width: 8em;
    text-align:center;
    padding-bottom:2%;
    border-bottom: 3px solid #ff6600;
    
}

.sectors_cards {
    display:flex;
    flex-direction: row;
    width: 100%;
    align-content: space-between;
}

.card {
    width: 30%;
    margin-right: 3%;
    margin-left: 3%;

   
}

@media screen and (max-width: 780px) {
    .sectors_cards  {
      display: flex;
      flex-direction: column;
    }
    .card {
        width: 80%;
        padding-top: 3%;
        padding-bottom: 3%;
        margin-right: auto;
        margin-left: auto;
       
    }
  }



 /* background-color:rgba(3, 73, 78, 0.2) */

 /* 
 width:100%;
 justify-content: center;
 margin:0px;
 padding-top:3%;
 */