
.footer_top {
    width: 100%;
    background-color: rgba(3, 73, 78, 0.92 );
    padding-top:5%;
    padding-bottom:5%;
    margin:0%;
    color:white;
    display: flex;
    flex-direction: row;
}

.footer_top_left {
    width: 70%;
    margin-left:10%;

}

.footer_top_right {
    border: 2px solid white;
    padding: 2%;
    margin:auto;
    text-align: center;
    
}

.footer_top_right:hover {
    border: 3px solid white;
    cursor: pointer;
    
}


@media only screen and (max-width: 780px) {
    .footer_top_right {
        margin-right: 3%;
        font-size: 10px;

    }
    .footer_top_left {
        margin-left: 5%;
        font-size: 10px;

    }

}

/*  rgba(3, 73, 78, 0.92 
#ff6600

*/