.news2 {
    display:flex;
    flex-direction: row;
    width:100%;
}


.news2left {
    display:flex;
    flex-direction: column;
    margin-right:auto;
    background-repeat: no-repeat;
    background-size:cover;
    background-position: center center ;
    min-height: 80vh;
    width:40%;
    margin-left:10%;
    border-radius:4%;
}

.news2right {
    width:40%;
    margin-left:auto;
}


@media only screen and (max-width: 780px) {
    .news2 {
        display:flex;
        flex-direction: column;
        width:100%; 
    }
    .news2right {
        width:90%;
        margin-left:auto;
        margin-right:auto;
        justify-content: center;
        padding-top:10%;
        padding-bottom:10%;
    }
    .news2right_top {
        width: 90%;
        margin-left:auto;
        margin-right:auto;
        justify-content: center;
        padding-right:0%;
        padding-left:0%;
    }

    .news2left {
        min-height: 50vh;
        padding-left: 0%;
        padding-right: 0%;
        width:90%;
        margin-left:auto;
        margin-right:auto;
        justify-content: center;

    }
    

}