.solutions {
    text-align:left;
    margin-left:auto;
    margin-right:auto;
    justify-content: center;
    border: solid 5px black;
    padding:3%;
    margin-top: 8%;
}

.icon_scroll {
    color:black;
    width: 100%;
    font-size: 15rem;
    margin-left:auto;
    margin-right:auto;
    justify-content: center;
    

}

.products {
    background-repeat: no-repeat;
    background-size:cover;
    background-position: center center ;
    min-height: 80vh;
}


.products_main {
    background-color: none;
    padding-top:0%;
    margin-top:0%;
    width: 100%;
    display: flex;
    flex-direction: column;
  
}



.icons_mega {
    display: flex;
    flex-direction: column;
    width:100%;
    background-color: rgba(3, 73, 78, 0.60);
    border-top-left-radius: 4%;
    border-top-right-radius: 4%;

}
.icons {
    display:flex;
    flex-direction: row;
    width: 100%;
    margin-left:auto;
    margin-right:auto;
    justify-content: center;

}

.icon  {
    display:flex;
    flex-direction:column;
    width: 20%;
    padding-left:2%;
    margin-bottom:2%;
   
}

.icon:hover h2 {
    border-left: solid 2px #ff6600
}

.icon_a {
    font-size: 40px !important;
    margin-right:auto;
    margin-left:auto;
    justify-content: center;
    margin-top:5%;
    padding-bottom: 5%;
    cursor:pointer;


}

.icon  h2{ 
    text-align:center;
    cursor:pointer;

}

.products_header {
    margin-top: 7%;
}

.sectors_tag {
    border-bottom: solid 2px #ff6600 !important;

}
@media only screen and (max-width: 780px) {
    .icons {
        display:flex;
        flex-direction: column;
        width:100%;

    }

    .icon {
        width: 60%;
        margin-left:auto;
        margin-right:auto;
        justify-content: center;
     

    }
    .footer_top2 {
        font-size: 40%;
  
    }

    .products_main {
        margin-top:auto;
        margin-bottom:auto;
        justify-content: center;

        width: 100%;

      
    }

    .icons_mega {
        border-radius:1%;

    }


}

