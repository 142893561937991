#root { height: 100% ;
background-color: #BDC3C7;}

body {
  position: relative;

}

body {
  background: #BDC3C7;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}

.swiper-container {
  width: 100%;
  height: 100%;
  margin-top: 4%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #BDC3C7;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;

}

.slide {
    width: 100%;
    height: 300px;
    object-fit: contain;
    background-size: cover; /* Resize the background image to cover the entire container */
    border-radius: 3%;
    color: white;
    text-align: left;
    margin:0%;
    padding:0%;
  
}

.slide h2 {
  padding-left:5%;
  padding-top:30%;

  
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;

}


@media only screen and (max-width: 780px) {
  .slide h2 {
      padding-left:15%;
      padding-right:15%;

  } 

  .body {
    height: 100%;
  }

  

}