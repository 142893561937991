.section_first {
 
    width: 100%;
 

}

.section_first_textcontainer {
    display: flex;
    flex-direction: column;
    padding-top: 8%;
    z-index:3;
    width: 90%;
    margin-left: 5%;

    margin-top: 0%;
    color: #ffffff;
    font-size: 20px;
    border-radius: 5%;
    font-weight:normal;

    
}

.spanintext {
    color: #ff6600;

}

.section_first_textcontainer_button {

    background-color:rgba(3, 73, 78, 0.92);
    padding: 2%;
    border: none;
    cursor:pointer;
    color:white;
    margin-right:auto;
    justify-content: center;
    border-bottom: solid 1px #ff6600;
    border-top-left-radius: 5%;
    border-top-right-radius: 5%;
    width: 20%;
    font-size: 16px;
    font-weight: bold;	

}

.section_first_textcontainer_button:hover {
    color:#ff6600;
    font-weight: bold;	

}

@media only screen and (max-width: 780px) {
    .section_first_textcontainer {
      width: 50%;
      margin-left:auto;
      justify-content: left;
      text-align:left;
      font-size: 14px;
      margin-top: 10%;
    }

    .section_first_textcontainer_button  {
        width: 60%;
        margin-left:auto;
        margin-right:auto;
        justify-content: right;
        font-size: 14px;

  
    }
}