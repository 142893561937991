.title_space {
    width:100%;
    height: min 100vh;
    background-color: white;
    padding-top:0%;
    margin-top:0%;
    display:flex;
    flex-direction: row;
}


.title_main {
    width:28% ;
    margin-right:auto;
    font-size: 26px;

    margin-left:10%;

}

.title_main h1 {
    padding-left:5%;
    border-left: solid 4px #ff6600 ;

}


.title_caption {
    margin-left:auto;
    margin-top:auto;
    margin-bottom:auto;
    justify-content: center;
    width: 30%;
    margin-right:10%;
    border-left: solid 2px #ff6600 ;
    padding-left:3%;
}

@media only screen and (max-width: 780px) {
    .title_space {
        width:100%;
        display:flex;
        flex-direction: column;
        background-color: white;
        padding-top:0%;
        margin-top:0%;
    } 
    .title_main {
        width:100% ;
        margin-right:auto;
        margin-left:auto;
        justify-content: center;
        font-size: 14px;
        padding:0%;
    }
    .title_caption {
        width:70%;
        margin-right:auto;
        margin-left:auto;
        justify-content: center;
        margin-bottom:5%;
    }
    .title_main h1 {
        width:28%;
        padding-left:5%;
        border-left: solid 4px #ff6600 ;
        margin-right:auto;
        margin-left:auto;
        justify-content: center;
    
    }

}