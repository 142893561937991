
.navbar_main {
    background-color: white;
    width: 100%;
    display: flex;
    flex-direction: row;
    color:black;
    border-bottom: 2px solid #ff6600;

}

.navbar__mainright {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-right: 5%;
    margin-left: auto;
    padding-top: 25px;
    padding-bottom: 25px;
    

}
.navbar__button {
    background-color: transparent;
    border: none;
    cursor:pointer;
    color:black;

}

.navbar__button_main {
    background-color: transparent;
    border: none;
    cursor:pointer;
    color:black;
    font-weight: bolder;

}

.navbar__button:hover {
    font-weight: bold;	
    border-bottom: solid 1px #ff6600;
    
}

.navbar__button_main:hover {
    font-weight: bold;	
    border-bottom: solid 1px #ff6600;
    
}

.navbar__mainleft {
    display: flex;
    flex-direction: column;
    column-gap: 0px;
    padding-top: 25px;
    padding-bottom: 25px;
    cursor:pointer;
    padding-left: 5%;

}

.navbar_lef_element {
    margin-top: 0px;
    margin-bottom:0px;
}

.navbar_lef_element2 {
    margin-top: 0px;
    margin-bottom:0px;
    color:#ff6600

}

@media only screen and (max-width: 780px) {
    .navbar__mainright {
        display:none;

    } }