
.footer_bottom {
    display: flex;
    flex-direction: row;
    background-color: white;
    width:100%;
    padding-top:0px;
    padding-bottom:0px;
    margin-top:0px;
    margin-bottom:0px;
    color: gray;
    font-weight: lighter;

}


.footer_logo {
    object-fit: contain;
    height:15px;
    padding: 0%;
    margin-right:auto;
    margin-left:5%;
    justify-content: center;
    margin-top:auto;
    margin-bottom:auto;


}

.footer_bottom_text {
    margin-left:auto;
    margin-right:5%;
    padding:0%;
    font-weight: 15px;

}

@media only screen and (max-width: 780px) {
    .footer_bottom {
        background-color: white;
        

    }
}
