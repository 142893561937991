#root { height: 100% }
html,
body {
  position: relative;
  height: 100%;
}

body {

  margin: 0;
  padding: 0;
}

.swiper-container {
  width: 100%;
  height: 100%;
}


.swiper-slide {
  text-align: center;
  font-size: 18px;


  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

